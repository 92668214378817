import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('./views/Dashboard.vue')
    },
    {
      path: '/view/:symbol',
      name: 'infoview',
      component: () => import('./views/InfoView.vue'),
      props: true
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: () => import('./views/AboutUs.vue'),
      props: true
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/ContactUs.vue'),
      props: true
    },
    {
      path: '/airdrop-swc',
      name: 'airdrop',
      component: () => import('./views/Airdrop.vue'),
      props: true
    }
  ]
})
router.beforeEach((to, from, next) => {
  next()
})
export default router;