<template>
    <div id="app">
        <LayoutPage></LayoutPage>
    </div>
</template>
<script>
  import LayoutPage from './layout/LayoutPage.vue'
  export default {
    components: {
      LayoutPage
    }
  }
</script>
